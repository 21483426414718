<template>
  <v-simple-table class="default-table">
    <template v-slot:default>
      <tbody>
        <tr>
          <th class="text-center">Motoryka duża</th>
          <th class="text-center">Motoryka mała</th>
          <th class="text-center">Komunikacja</th>
          <th class="text-center">Obszar poznawczy</th>
          <th class="text-center">Obszar społeczno-poznawczy</th>
        </tr>
        <tr>
          <TextAreaCell
            :value.sync="value.gross_motor_skills"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            :value.sync="value.fine_motor_skills"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            :value.sync="value.communication"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            :value.sync="value.cognitive_area"
            :disabled="disabled"
          ></TextAreaCell>
          <TextAreaCell
            :value.sync="value.social_emotional_area"
            :disabled="disabled"
          ></TextAreaCell>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LabelDateField: () => import("@/components/LabelDateField"),
    TextAreaCell: () => import("@/components/tables/TextAreaCell"),
  },
  methods: {
    maxlengthRule(text) {
      return (text && text.length < 1000) || `Maksymalna ilość znaków to 1000, aktualnie masz ${text.length}.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.default-table {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
}
.default-table ::v-deep td {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 90px;
}
.default-table ::v-deep input {
  text-align: center;
}
.default-table ::v-deep th {
  border-collapse: collapse !important;
  border: 0.5px lightgray solid !important;
  color: black !important;
  font-size: 14px !important;
}
.default-table ::v-deep tr:hover {
  background-color: transparent !important;
}
</style>